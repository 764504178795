import { Buffer } from 'buffer';
import { publicEncrypt } from 'public-encrypt';

/**
 * Returns the plaintext argument encrypted via the RSA algorithm with OAEP padding, base64 encoded
 * https://github.com/crypto-browserify/publicEncrypt
 */
export const encrypt = (key: string, plaintext: string): string => {
  return publicEncrypt(key, Buffer.from(plaintext)).toString('base64');
};
