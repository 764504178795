import MoreVertIcon from '@mui/icons-material/MoreVertOutlined';
import React, { ComponentProps, ReactElement, ReactNode, useCallback, useMemo } from 'react';

import {
  Button,
  Drawer,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useMenu,
  useTheme,
} from '~/components';
import { getSxPropsArray, useIsMediumScreen } from '~/utils';

export interface KebabMenuItem<T extends object | undefined = undefined> {
  callback: (callbackArgs?: T) => void;
  dataHeap?: string;
  icon?: ReactNode;
  text: NonNullable<ReactNode>;
}

export interface Props<T extends object | undefined = undefined> {
  ariaLabel?: string;
  buttonContent?: ReactNode;
  buttonProps?: Partial<ComponentProps<typeof Button>>;
  callbackArgs?: T;
  dataQa?: string;
  disableCtas?: boolean;
  drawerProps?: Partial<ComponentProps<typeof Drawer>>;
  items: KebabMenuItem<T>[];
  menuListProps?: Partial<ComponentProps<typeof MenuItem>>;
  menuProps?: Partial<ComponentProps<typeof Menu>>;
}

const getIcon = (icon: ReactNode): ReactNode => (typeof icon === 'string' ? <img alt="" src={icon} /> : icon);

export const KebabMenu = <T extends object | undefined = undefined>({
  ariaLabel,
  buttonContent,
  buttonProps = { variant: 'outlined' },
  callbackArgs,
  dataQa = 'kebab-menu',
  disableCtas = false,
  drawerProps,
  items,
  menuProps,
  menuListProps,
}: Props<T>): ReactElement | null => {
  const isMobile = useIsMediumScreen();
  const {
    sfKebabMenu: { styles, typographyVariants },
  } = useTheme();
  const { buttonProps: baseButtonProps, menuProps: baseMenuProps } = useMenu('kebab-menu');

  const onMenuItemClick = useCallback(
    (item: KebabMenuItem<T>) => {
      item.callback(callbackArgs);
      baseMenuProps.onClose();
    },
    [baseMenuProps, callbackArgs],
  );

  const drawerActionItems = items.map(item => ({
    dataHeap: item.dataHeap,
    icon: getIcon(item.icon),
    label: item.text,
    onClick: () => onMenuItemClick(item),
  }));

  const menuItems = useMemo(
    () =>
      items.map((item, index) => (
        <MenuItem
          data-heap={item.dataHeap}
          disabled={disableCtas}
          key={index}
          onClick={() => onMenuItemClick(item)}
          sx={isMobile ? styles.mobileMenuItem : menuListProps?.sx}
        >
          {item.icon && <ListItemIcon data-qa={`${dataQa}-icon`}>{getIcon(item.icon)}</ListItemIcon>}
          <ListItemText data-qa={`${dataQa}-text`}>
            {typeof item.text === 'string' ? (
              <Typography variant={typographyVariants?.listItem}>{item.text}</Typography>
            ) : (
              item.text
            )}
          </ListItemText>
        </MenuItem>
      )),
    [dataQa, disableCtas, isMobile, items, onMenuItemClick, styles.mobileMenuItem, typographyVariants?.listItem],
  );

  const buttonPropsSx = buttonProps.sx ?? [];
  const buttonStyles = styles.button ?? [];
  return (
    <>
      <Button
        aria-label={ariaLabel ?? 'more options'}
        data-qa={`${dataQa}-cta`}
        {...baseButtonProps}
        {...buttonProps}
        sx={[...getSxPropsArray(buttonStyles), ...getSxPropsArray(buttonPropsSx)]}
      >
        {buttonContent ?? <MoreVertIcon />}
      </Button>
      {isMobile && (
        <Drawer
          data-qa={`${dataQa}-mobile`}
          menuItems={drawerActionItems}
          onClose={baseMenuProps.onClose}
          open={baseMenuProps.open}
          {...drawerProps}
        />
      )}
      {!isMobile && (
        <Menu data-qa={`${dataQa}-desktop`} {...baseMenuProps} {...menuProps}>
          {menuItems}
        </Menu>
      )}
    </>
  );
};
