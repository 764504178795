/**
 * Instead of bringing in a whole library, I'm just copying this custom hook from:
 * https://usehooks-typescript.com/react-hook/use-read-local-storage
 * https://github.com/juliencrn/usehooks.ts/blob/master/packages/usehooks-ts/src/useReadLocalStorage/useReadLocalStorage.ts
 */
import { useEffect, useState } from 'react';

type Value<T> = T | null;

/**
 * A hook to return the localStorage value for a given key.
 * @param key - The localStorage key value to return.
 * @returns The stored value in the localStorage for the key.
 */
export const useReadLocalStorage = <T>(key: string): Value<T> => {
  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = (): Value<T> => {
    // Prevent build error "window is undefined" but keep keep working
    if (typeof window === 'undefined') {
      return null;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : null;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return null;
    }
  };

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<Value<T>>(readValue);

  // Listen if localStorage changes
  useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValue());
    };

    // this only works for other documents, not the current one
    window.addEventListener('storage', handleStorageChange);

    // this is a custom event, triggered in writeValueToLocalStorage
    // See: useLocalStorage()
    window.addEventListener('local-storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('local-storage', handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return storedValue;
};
