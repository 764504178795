import { pipe } from 'fp-ts/lib/pipeable';

import { encrypt } from '../cryptography';

export interface QueryParam {
  encrypt?: boolean;
  value: string;
}

export const getDeepLink = (
  baseUrl: string,
  queryParams: Record<string, QueryParam>,
  encryptionOptions: { key: string },
): string => {
  const queryString = Object.entries(queryParams)
    .map(([key, { encrypt: shouldEncrypt, value }]) => {
      return `${key}=${pipe(
        value,
        val => (shouldEncrypt ? encrypt(encryptionOptions.key, val) : val),
        encodeURIComponent,
      )}`;
    })
    .join('&');
  return `${baseUrl}?${queryString}`;
};
