/* eslint-disable no-useless-escape */
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import {
  addYears,
  differenceInCalendarDays,
  differenceInCalendarYears,
  isLeapYear,
  isValid,
  parseISO,
  startOfDay,
  startOfToday,
} from 'date-fns';
import { filter, range } from 'fp-ts/lib/Array';
import { pipe } from 'fp-ts/lib/function';
import { groupBy } from 'fp-ts/lib/NonEmptyArray';
import { fromNullable, map } from 'fp-ts/lib/Option';
import React from 'react';

import {
  BankAccountAttribute,
  BankAccountStringAttribute,
  ProductName,
  savingsOptimizerAccountProgramContentKey,
} from './types';

import { FinancialAccountAssociationVerificationStatus, OnboardingStates } from '~/__generated__';
import {
  BalanceType,
  BankAccountInput,
  BankAccountInputWithParty,
  BankAccountType,
  FinancialAccountSource,
  FinancialAccountStatus,
  FinancialAccountType,
  LegalDocumentStatus,
  ManagedProductStatus,
  ManagedProductType,
  PlanUpdateWorkflowStatus,
  ScheduledTransferStatus,
  SuspensionType,
  TransferType,
  UpdateWorkflowStatus,
} from '~/__generated__/symphonyTypes';
import { PortfolioAccount } from '~/components/modals/PortfolioCompareModal/types';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { isOneTime, LegalDocumentSignee, ScheduledTransfer } from '~/containers/AccountSummary/symphony';
import { AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_legalDocuments } from '~/containers/AccountSummary/symphony/__generated__/AccountSummaryGetDigitalWealthAccounts';
import { GetProductAccountStateData_managedProduct_legalDocuments } from '~/containers/FADashboard/ApplicationInProgress/ApplicationInProgressStatusColumn/symphony/__generated__/GetProductAccountStateData';
import { AccountDetailsTabsEnum } from '~/hooks';
import {
  BrokerageFinancialAccount,
  ExternalBrokerageFinancialAccount,
  FinancialAccount,
  isManagedProduct,
} from '~/hooks/financial-account/symphony';
import { GetBankAccountsForTransfer_client_financialAccounts_attributes } from '~/hooks/financial-account/symphony/__generated__/GetBankAccountsForTransfer';
import { GetBankAccountsForTransfer_client_financialAccounts } from '~/hooks/financial-account/symphony/__generated__/GetBankAccountsForTransfer';
import { formatMaskedAccountNumber } from '~/utils';
import { SymphonyAttribute, SymphonyBooleanAttribute, SymphonyStringAttribute } from '~/utils/types';

export * from './mock';

export enum AccountState {
  Closed = 'Closed',
  DocsAndAccountReady = 'DocsAndAccountReady',
  DocsError = 'DocsError',
  DocsReady = 'DocsReady',
  DocsSigned = 'DocsSigned',
  DocsSubmitted = 'DocsSubmitted' /* maybe not needed */,
  DocsWaitingForOtherClient = 'DocsWaitingForOtherClient',
  DocsWaitingForPrimaryClient = 'DocsWaitingForPrimaryClient',
  DocsWaitingForPrimaryFinancialAdvisor = 'DocsWaitingForPrimaryFinancialAdvisor',
  FundingBelowMinimum = 'FundingBelowMinimum',
  FundingError = 'FundingError',
  FundingPending = 'FundingPending',
  OnboardingIncomplete = 'OnboardingIncomplete',
  OnboardingWaitingForDocs = 'OnboardingWaitingForDocs',
  PendingClosed = 'PendingClosed',
  PendingModelPortfolioChange = 'PendingModelPortfolioChange',
  RebalanceCompleted = 'RebalanceCompleted',
  RebalancePending = 'RebalancePending',
  Suspended = 'Suspended',
  Unknown = 'Unknown',
}

export interface DrawerParams {
  initialTab?: AccountDetailsTabsEnum;
  managedProductId?: string;
  partyId: string;
}

export enum DrawerInstance {
  ACCOUNT_DETAIL = 'ACCOUNT_DETAIL',
  ACCOUNT_PERFORMANCE = 'ACCOUNT_PERFORMANCE',
  ACCOUNT_SUMMARY = 'ACCOUNT_SUMMARY',
}

export interface AccountStateData {
  firstRebalancedOn?: string;
  onboardingSignees?: LegalDocumentSignee[];
  primaryClientPartyId?: string;
  shortfall?: number;
  startedOn?: string;
}

export enum SuspensionTag {
  BORD = 'BORD',
  CUSTODIAL = 'CUSTODIAL',
  OPS = 'OPS',
}

export interface TradingSuspension {
  createdAt?: string;
  suspensionTag: string | null;
  suspensionType: SuspensionType;
}

export type LegalDocument =
  | AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_legalDocuments
  | GetProductAccountStateData_managedProduct_legalDocuments;

export const BankAccountTemporaryIdPrefix = 'temporary-id';

export type BankAccount = Partial<GetBankAccountsForTransfer_client_financialAccounts>;

export const getBankAccountType = (type: FinancialAccountType): BankAccountType => {
  switch (type) {
    case FinancialAccountType.CHEQUING:
      return BankAccountType.CHEQUING;
    case FinancialAccountType.SAVINGS:
      return BankAccountType.SAVINGS;
    default:
      throw new Error(`Cannot map ${type} to BankAccountType`);
  }
};

export const isValidDayOfMonth = (value: Date): boolean => {
  const dayOfMonth = value.getDate();
  return dayOfMonth > 0 && dayOfMonth < 29;
};

interface SortAccountsByFinancialInstitutionAndMaskedNumber {
  attributes: GetBankAccountsForTransfer_client_financialAccounts_attributes[] | null;
  financialInstitution: string | null;
  maskedAccountNumber: string | null;
}

const isStringAttribute = (attribute: SymphonyAttribute): attribute is SymphonyStringAttribute => {
  return attribute.__typename === 'StringAttribute';
};

export const isBooleanAttribute = (attribute: SymphonyAttribute): attribute is SymphonyBooleanAttribute => {
  return attribute.__typename === 'BooleanAttribute';
};

export const getPlaidMaskedAccountNumber = (attributes: SymphonyAttribute[] | null | undefined): string | undefined => {
  return attributes?.filter(isStringAttribute)?.find(attr => attr.name === 'PLAID_MASKED_ACCOUNT_NUMBER')?.stringValue;
};

export const sortAccountsByFinancialInstitutionAndMaskedNumber = (
  accounts: Partial<SortAccountsByFinancialInstitutionAndMaskedNumber>[],
): (BankAccount | BrokerageFinancialAccount | ExternalBrokerageFinancialAccount)[] => {
  return [...accounts].sort((account1, account2) => {
    const financialInstitution1 = account1.financialInstitution ? account1.financialInstitution : 'unknown';
    const financialInstitution2 = account2.financialInstitution ? account2.financialInstitution : 'unknown';
    const plaidMaskedAccountNumber1 = getPlaidMaskedAccountNumber(account1.attributes);
    const plaidMaskedAccountNumber2 = getPlaidMaskedAccountNumber(account2.attributes);
    const maskedAccountNumber1 = plaidMaskedAccountNumber1 ?? account1.maskedAccountNumber ?? 'unknown';
    const lastFourCharsMaskedAccountNumber1 = maskedAccountNumber1.slice(-4);
    const maskedAccountNumber2 = plaidMaskedAccountNumber2 ?? account2.maskedAccountNumber ?? 'unknown';
    const lastFourCharsMaskedAccountNumber2 = maskedAccountNumber2.slice(-4);

    if (financialInstitution1 !== financialInstitution2) {
      if (
        (financialInstitution1[0] === financialInstitution1[0].toLocaleLowerCase() &&
          financialInstitution2[0] === financialInstitution2[0].toLocaleUpperCase()) ||
        (financialInstitution1[0] === financialInstitution1[0].toLocaleUpperCase() &&
          financialInstitution2[0] === financialInstitution2[0].toLocaleLowerCase())
      ) {
        return financialInstitution1.localeCompare(financialInstitution2);
      }

      if (financialInstitution1 < financialInstitution2) {
        return -1;
      }

      return 1;
    } else {
      if (lastFourCharsMaskedAccountNumber1 > lastFourCharsMaskedAccountNumber2) {
        return 1;
      } else if (lastFourCharsMaskedAccountNumber1 < lastFourCharsMaskedAccountNumber2) {
        return -1;
      }
    }

    return 0;
  });
};

export const sortBankAccounts = (accounts: (BankAccount | FinancialAccount)[]): BankAccount[] => {
  return sortAccountsByFinancialInstitutionAndMaskedNumber(accounts) as BankAccount[];
};
export const sortBrokerageAccounts = (
  accounts: (BrokerageFinancialAccount | ExternalBrokerageFinancialAccount)[],
): (BrokerageFinancialAccount | ExternalBrokerageFinancialAccount)[] => {
  return sortAccountsByFinancialInstitutionAndMaskedNumber(accounts) as (
    | BrokerageFinancialAccount
    | ExternalBrokerageFinancialAccount
  )[];
};

export const getExternalBankAccount = ({
  accountNumber,
  attributes,
  financialInstitution,
  nameOnBankAccount,
  partyId,
  productName,
  routingNumber,
  type,
}: BankAccountInput & { partyId: string }): BankAccountInputWithParty => {
  return {
    bankAccount: {
      type,
      isSynced: true,
      nameOnBankAccount,
      accountNumber,
      financialInstitution,
      productName,
      routingNumber,
      attributes,
    },
    partyId,
  };
};

export const getExternalBankAccountInput = (
  bankAccountId: string,
  bankAccounts: {
    accountNumber?: string | null;
    attributes?: BankAccountAttribute[] | null;
    financialInstitution?: string | null;
    id?: string | null;
    nameOnBankAccount?: string | null;
    productName?: string | null;
    routingNumber?: string | null;
    type?: FinancialAccountType;
  }[],
  user: { name: string; partyId: string },
): BankAccountInputWithParty | null => {
  const isExternalBankAccountSelected = !!bankAccountId && bankAccountId.includes(BankAccountTemporaryIdPrefix);
  const bankAccount = bankAccounts.find(account => account.id === bankAccountId);
  return bankAccount && isExternalBankAccountSelected
    ? getExternalBankAccount({
        accountNumber: bankAccount.accountNumber ?? '',
        attributes: bankAccount.attributes
          ?.filter((attribute): attribute is BankAccountStringAttribute => attribute.__typename === 'StringAttribute')
          ?.map(({ name, stringValue }) => ({ name, value: stringValue })),
        financialInstitution: bankAccount.financialInstitution ?? '',
        nameOnBankAccount: bankAccount.nameOnBankAccount ? bankAccount.nameOnBankAccount : user.name, // Can't use ?? because we want to use user.name when nameOnBankAccount is empty ('') as well
        routingNumber: bankAccount.routingNumber ?? '',
        partyId: user.partyId,
        productName: bankAccount.productName,
        type: getBankAccountType(bankAccount.type ?? FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE),
      })
    : null;
};

export const getAccountBalance = (account: Pick<BankAccount, 'balances'>, type: BalanceType): number | undefined => {
  return account.balances
    ?.filter(balances => balances.type === type)
    .map(balances => {
      return parseFloat(balances.balance.value ?? '0');
    })
    .reduce((a, b) => a + b, 0);
};

export const getBankAccountId = (
  accountId: string | null,
  isFromExternalSource: boolean | null,
  accountNumber: string | null,
): string | null => {
  return isFromExternalSource && !accountId ? `${BankAccountTemporaryIdPrefix}-${accountNumber}` : accountId ?? null;
};

export const getBankAccountOptions = (
  accounts: BankAccount[],
  noEligibleAccountsMsg: string,
  editDetailsOnClick?: (item: DropdownItem) => void,
): DropdownItem[] => {
  const options: DropdownItem[] = [];
  sortBankAccounts(accounts).forEach(account => {
    const accountId = getBankAccountId(
      account.id ?? null,
      account.isFromExternalSource ?? null,
      account.accountNumber ?? null,
    );
    if (accountId) {
      const maskedAccountNumber = getPlaidMaskedAccountNumber(account.attributes) ?? account.maskedAccountNumber;
      options.push({
        value: accountId,
        label: getBankAccountLabel(
          getUpdatedFinancialInstituion(account.financialInstitution, account.type),
          account.productName,
          maskedAccountNumber,
        ),
        editDetailsOnClick:
          isBankAccountEditable(account) && account.source !== FinancialAccountSource.PLAID
            ? editDetailsOnClick
            : undefined,
      });
    }
  });

  if (options.length < 1) {
    options.push({ value: noEligibleAccountsMsg, label: noEligibleAccountsMsg });
  }

  return options;
};

export const getBankAccountLabel = (
  financialInstitution?: string | null,
  productName?: string | null,
  maskedAccountNumber?: string | null,
  accountNumberFormat?: string,
): string => {
  return `${financialInstitution ?? ''} ${productName ? `${productName} ` : ''}${
    formatMaskedAccountNumber(accountNumberFormat, maskedAccountNumber) ?? 'unknown'
  }`;
};

export const getUpdatedFinancialInstituion = (
  financialInstitution?: string | null,
  accountType?: string | null,
): string => {
  return financialInstitution
    ? financialInstitution
    : accountType === FinancialAccountType.CHEQUING
    ? 'Checking'
    : 'Savings';
};

export const getAmountFieldHelperText = (
  amountFieldError: any,
  currencySymbol: string,
  minAmount: number,
  maxAmount: number,
): string | null => {
  if (amountFieldError) {
    if (amountFieldError.type === 'min') {
      return `You cannot have a transfer amount less than ${currencySymbol}${minAmount}`;
    } else if (amountFieldError.type === 'max') {
      return `You cannot have a transfer amount greater than ${currencySymbol}${maxAmount}`;
    } else if (amountFieldError.type === 'required') {
      return 'You must specify a transfer amount';
    }
  }
  return null;
};

export const getContributionYearOptions = (): DropdownItem[] =>
  range(0, 1).map(value => ({
    value: new Date().getFullYear() - value,
    label: (new Date().getFullYear() - value).toString(),
  }));

export const inheritedRetirementAccountTypes = [
  FinancialAccountType.INHERITED_IRA,
  FinancialAccountType.INHERITED_ROTH_IRA,
  FinancialAccountType.INHERITED_TRADITIONAL_IRA,
  FinancialAccountType.BENEFICIARY_ROTH_IRA_MINOR_CUSTODIAN,
  FinancialAccountType.BENEFICIARY_ROTH_IRA_TRUST,
  FinancialAccountType.BENEFICIARY_TRADITIONAL_IRA_MINOR_CUSTODIAN,
  FinancialAccountType.BENEFICIARY_TRADITIONAL_IRA_TRUST,
];

export const retirementAccountTypes = [
  FinancialAccountType.ROTH_IRA,
  FinancialAccountType.ROTH_401K,
  FinancialAccountType.TRADITIONAL_IRA,
  FinancialAccountType.ROLLOVER_IRA,
  FinancialAccountType.SEP,
  FinancialAccountType.SEP_IRA,
  FinancialAccountType.CODA_SEP,
  FinancialAccountType.SIMPLE,
  FinancialAccountType.SIMPLE_IRA,
  FinancialAccountType._401K,
  FinancialAccountType.FOUR_ZERO_ONE_K,
  FinancialAccountType.FOUR_ZERO_THREE_B,
  FinancialAccountType.FIVE_TWO_NINE,
  FinancialAccountType.KEOGH,
  FinancialAccountType.LIR,
  FinancialAccountType.LRI,
  FinancialAccountType.LIS,
  FinancialAccountType.PRI,
  FinancialAccountType.RIS,
  FinancialAccountType.RRI,
  FinancialAccountType.RRS,
  FinancialAccountType.RSS,
  FinancialAccountType.TFS,
  FinancialAccountType.RDS,
  ...inheritedRetirementAccountTypes,
];

/**
 * Returns true if the account type is a retirement account
 * @param accountType
 */
export const isRetirementAccountType = (accountType: FinancialAccountType): boolean =>
  retirementAccountTypes.includes(accountType);

export const isInheritedRetirementAccount = (accountType: FinancialAccountType): boolean =>
  inheritedRetirementAccountTypes.includes(accountType);

export const maskAccountNumber = (accountNumber: string, lastCharactersToKeep = 4, maskString = '*'): string => {
  return `${maskString}${
    accountNumber.length > lastCharactersToKeep
      ? accountNumber.substring(accountNumber.length - lastCharactersToKeep, accountNumber.length)
      : accountNumber
  }`;
};

export const getClientAgeFromBirthDate = (birthdate: string): number | null => {
  if (!isValid(parseISO(birthdate))) {
    return null;
  }
  const birthDay = startOfDay(parseISO(birthdate));
  const clientAgeYears = differenceInCalendarYears(startOfToday(), birthDay);
  const clientAgeFraction =
    differenceInCalendarDays(startOfToday(), addYears(birthDay, clientAgeYears)) /
    (isLeapYear(startOfToday()) ? 366 : 365);
  return clientAgeYears + clientAgeFraction;
};

export const nonPendingLegalDocumentStatuses = [
  LegalDocumentStatus.SUCCEEDED,
  LegalDocumentStatus.FAILED,
  LegalDocumentStatus.DECLINED,
];

const sumTransfers = (sum: number, { total }: ScheduledTransfer) => parseFloat(total.value) + sum;

const transferStatus = ({ status }: ScheduledTransfer) => {
  switch (status) {
    case ScheduledTransferStatus.COMPLETED:
    case ScheduledTransferStatus.GENERAL_ERROR:
      return status;
    // don't care about these statuses
    case ScheduledTransferStatus.CANCELLED:
    case ScheduledTransferStatus.TEST:
      return 'ignore';
    // everything else is considered pending
    default:
      return ScheduledTransferStatus.PENDING;
  }
};

export const isPendingPlanUpdateWorkflowStatus = (planUpdateWorkflowStatus?: PlanUpdateWorkflowStatus): boolean =>
  !!planUpdateWorkflowStatus &&
  [
    PlanUpdateWorkflowStatus.PENDING,
    PlanUpdateWorkflowStatus.DOCS_SIGNED,
    PlanUpdateWorkflowStatus.DOCS_PREPARED,
  ].includes(planUpdateWorkflowStatus);

export const isPendingEntityUpdateWorkflowStatus = (entityUpdateWorkflowStatus?: UpdateWorkflowStatus): boolean =>
  !!entityUpdateWorkflowStatus && [UpdateWorkflowStatus.DOCS_PREPARED].includes(entityUpdateWorkflowStatus);

export const getLegalDocumentForPendingModelPortfolioUpdate = (
  legalDocuments: LegalDocument[],
  pendingPlanUpdateWorkflowId?: string,
): LegalDocument | undefined =>
  pendingPlanUpdateWorkflowId
    ? legalDocuments.find(ld => ld.associatedEntities?.planUpdateWorkflowIds.includes(pendingPlanUpdateWorkflowId))
    : undefined;

export const getLegalDocumentForPendingAssetTransfers = (
  legalDocuments: LegalDocument[],
  pendingAssetDepositId?: string,
): LegalDocument | undefined =>
  pendingAssetDepositId
    ? legalDocuments.find(ld => ld.associatedEntities?.assetDepositIds.includes(pendingAssetDepositId))
    : undefined;

export const getLegalDocumentForPendingAccountProfileUpdate = (
  legalDocuments: LegalDocument[],
  pendingAccountProfileUpdateWorkflowId?: string,
): LegalDocument | undefined =>
  pendingAccountProfileUpdateWorkflowId
    ? legalDocuments.find(ld =>
        ld.associatedEntities?.entityUpdateWorkflowIds.includes(pendingAccountProfileUpdateWorkflowId),
      )
    : undefined;

export const getLegalDocumentsForPendingBankAccountAssociation = (
  legalDocuments: LegalDocument[],
): LegalDocument[] | undefined =>
  legalDocuments.filter(
    legalDocument =>
      legalDocument.associatedEntities?.bankAccountAssociationIds.length &&
      ![LegalDocumentStatus.DECLINED, LegalDocumentStatus.FAILED, LegalDocumentStatus.SUCCEEDED].includes(
        legalDocument.status,
      ) &&
      legalDocument.signees.some(signee => signee.status === LegalDocumentStatus.PENDING),
  );

export const getActiveAccountState = ({
  accountMinimum,
  hasPendingPlanUpdateWorkflow,
  isRebalanced = false,
  isSuspendedAccountStateSupported = true,
  suspendedOn,
  transfers = [],
}: {
  accountMinimum?: number;
  hasPendingPlanUpdateWorkflow?: boolean;
  isRebalanced: boolean;
  isSuspendedAccountStateSupported?: boolean;
  suspendedOn?: string;
  transfers?: ScheduledTransfer[];
}) => {
  if (isSuspendedAccountStateSupported && suspendedOn) {
    return {
      state: AccountState.Suspended,
      data: {
        startedOn: suspendedOn,
      },
    };
  }

  if (hasPendingPlanUpdateWorkflow) {
    return { state: AccountState.PendingModelPortfolioChange };
  }

  if (isRebalanced) {
    return { state: AccountState.RebalanceCompleted };
  }
  const {
    [TransferType.DEPOSIT]: oneTimeDeposits = [] as ScheduledTransfer[],
    [TransferType.WITHDRAWAL]: oneTimeWithdrawals = [] as ScheduledTransfer[],
  } = pipe(
    transfers,
    filter(isOneTime),
    groupBy(({ type }) => type),
  );
  const {
    [ScheduledTransferStatus.COMPLETED]: completedDeposits = [] as ScheduledTransfer[],
    [ScheduledTransferStatus.GENERAL_ERROR]: errorDeposits = [] as ScheduledTransfer[],
    [ScheduledTransferStatus.PENDING]: pendingDeposits = [] as ScheduledTransfer[],
  } = pipe(oneTimeDeposits, groupBy(transferStatus));
  const {
    [ScheduledTransferStatus.COMPLETED]: completedWithdrawals = [] as ScheduledTransfer[],
    [ScheduledTransferStatus.PENDING]: pendingWithdrawals = [] as ScheduledTransfer[],
  } = pipe(oneTimeWithdrawals, groupBy(transferStatus));

  const currentValue = pipe(
    completedDeposits.reduce(sumTransfers, 0) - completedWithdrawals.reduce(sumTransfers, 0),
    val => val.toFixed(2),
    parseFloat,
  );
  const expectedValue = pipe(
    currentValue + pendingDeposits.reduce(sumTransfers, 0) - pendingWithdrawals.reduce(sumTransfers, 0),
    val => val.toFixed(2),
    parseFloat,
  );
  const minimumAmount = accountMinimum ?? -1;
  if (currentValue < minimumAmount) {
    if (expectedValue < minimumAmount) {
      // only care about errors if the funding amount is below the minimum
      return errorDeposits.length > 0
        ? { state: AccountState.FundingError }
        : { state: AccountState.FundingBelowMinimum, data: { shortfall: minimumAmount - expectedValue } };
    }
    return { state: AccountState.FundingPending };
  }
  /**
   * At this point the following are true:
   *   - financialAccountStatus === FinancialAccountStatus.ACTIVE
   *   - currentValue >= accountMinimum
   *   - transferStatus === ScheduledTransferStatus.COMPLETED
   *   - isRebalanced === false
   */
  return { state: AccountState.RebalancePending };
};

export const getAccountState = ({
  accountMinimum,
  accountNumber,
  financialAccountStatus,
  firstRebalancedOn,
  hasPendingPlanUpdateWorkflow,
  isSuspendedAccountStateSupported = true,
  legalDocuments,
  primaryAdvisorPartyId,
  primaryClientPartyId,
  suspendedOn = undefined,
  transfers = [],
}: {
  accountMinimum?: number;
  accountNumber?: string;
  financialAccountStatus: FinancialAccountStatus | ManagedProductStatus;
  firstRebalancedOn?: string;
  hasPendingPlanUpdateWorkflow?: boolean;
  isSuspendedAccountStateSupported?: boolean;
  legalDocuments?: LegalDocument[];
  primaryAdvisorPartyId?: string;
  primaryClientPartyId?: string;
  suspendedOn?: string | undefined;
  transfers?: ScheduledTransfer[];
}): { data?: AccountStateData; state: AccountState } => {
  switch (financialAccountStatus) {
    case FinancialAccountStatus.PARTIAL:
      return { state: AccountState.OnboardingIncomplete };
    /**
     * This is a transient state, in most cases an account will move immediately from NEW to LEGAL_DOCUMENTS_PREPARED.
     * For some partners, the account can stay remain in NEW for an extended period of time
     */
    case FinancialAccountStatus.NEW:
      return { state: AccountState.OnboardingWaitingForDocs };
    case FinancialAccountStatus.LEGAL_DOCUMENTS_PREPARED:
      const legalDocument = legalDocuments?.[0];
      if (!legalDocument) {
        // TODO DA2-512: Log warning to sentry
        console.warn(`Expected to find a legal document but none was found`);
      }

      const signees = legalDocument?.signees;
      const nextPendingSignee = getNextPendingSignee(signees ?? []);
      if (!nextPendingSignee?.partyId || !primaryClientPartyId) {
        // if we don't have the next signee's party id, or if we don't have the primary client party id
        // we cannot decide who the signing process is waiting on
        return { state: accountNumber ? AccountState.DocsAndAccountReady : AccountState.DocsReady };
      }
      if (nextPendingSignee.partyId === primaryClientPartyId) {
        return { state: AccountState.DocsWaitingForPrimaryClient, data: { onboardingSignees: signees } };
      }
      if (nextPendingSignee.partyId === primaryAdvisorPartyId) {
        return { state: AccountState.DocsWaitingForPrimaryFinancialAdvisor, data: { onboardingSignees: signees } };
      }
      return { state: AccountState.DocsWaitingForOtherClient, data: { onboardingSignees: signees } };
    case FinancialAccountStatus.LEGAL_DOCUMENTS_SIGNED:
      return { state: AccountState.DocsSigned };
    case FinancialAccountStatus.LEGAL_DOCUMENTS_SUBMITTED:
      return { state: AccountState.DocsSubmitted };
    case FinancialAccountStatus.LEGAL_DOCUMENTS_SIGNATURE_FAILED:
    case FinancialAccountStatus.ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED:
      return { state: AccountState.DocsError };
    case FinancialAccountStatus.PENDING_CLOSED:
      return {
        state: AccountState.PendingClosed,
        data: { firstRebalancedOn },
      };
    case FinancialAccountStatus.ACTIVE:
      const { state, data } = getActiveAccountState({
        accountMinimum,
        transfers,
        hasPendingPlanUpdateWorkflow,
        isRebalanced: !!firstRebalancedOn,
        suspendedOn,
        isSuspendedAccountStateSupported,
      });
      return {
        state,
        data: {
          ...data,
          firstRebalancedOn,
          primaryClientPartyId,
        },
      };
    case FinancialAccountStatus.CLOSED:
      return { state: AccountState.Closed };
    default:
      // TODO DA2-512: Log warning to sentry
      console.warn(`Could not determine account state`);
      return { state: AccountState.Unknown };
  }
};

export const getRequiredFunds = (accountMinimum?: string) =>
  pipe(
    fromNullable(accountMinimum),
    map(parseFloat),
    map(val => val.toFixed(2)),
    map(parseFloat),
  );

export const isPartnerOpsSuspension = (suspension: TradingSuspension) => {
  return suspension.suspensionTag === SuspensionTag.OPS && suspension.suspensionType === SuspensionType.PARTNER;
};

export const hasBooleanAttribute = (
  attributeName: string,
  attributes?: Array<{ booleanValue?: boolean; name: string }>,
): boolean => {
  const attr = attributes?.find(att => att.name === attributeName);
  return !!attr && 'booleanValue' in attr && !!attr.booleanValue;
};

export const isSavingsOptimizerAccount = (attributes?: Array<{ booleanValue?: boolean; name: string }>): boolean =>
  hasBooleanAttribute('IS_SAVE_AND_GROW', attributes);

export const getPlaidItemIdIfReAuthenticateFails = (
  bankAccounts: BankAccount[],
  bankAccountId: string,
): string | undefined => {
  const selectedBankAccount = bankAccounts.find(account => account.id === bankAccountId);
  const bankAccountBooleanAttribute = selectedBankAccount?.attributes?.filter(isBooleanAttribute);
  const bankAccountStringAttribute = selectedBankAccount?.attributes?.filter(isStringAttribute);

  const isAuthenticationExpired = !!bankAccountBooleanAttribute?.find(
    ({ name }) => name === 'PLAID_REQUIRES_REAUTHENTICATION',
  )?.booleanValue;

  if (isAuthenticationExpired) {
    return bankAccountStringAttribute?.find(({ name }) => name === 'PLAID_ITEM_ID')?.stringValue;
  }

  return undefined;
};

export const getAccountProgramText = (
  type: ManagedProductType | undefined,
  attributes: Array<{ booleanValue?: boolean; name: string }> | undefined,
  content: (ProductName | null)[] | null | undefined,
  useLongText = false,
): string => {
  const accountProgramKey = isSavingsOptimizerAccount(attributes) ? savingsOptimizerAccountProgramContentKey : type;
  return content?.find(item => item?.key === accountProgramKey)?.[useLongText ? 'long_text' : 'text'] || 'Unknown';
};

/**
 * @param managedProductRelatedParties - Related parties information for the managed product
 * @returns {string | undefined} Initial party id
 */
export const getPrimaryClientPartyId = (
  managedProductRelatedParties:
    | ({ isInitialParty: boolean | null; partyId: string | null } | null)[]
    | null
    | undefined,
): string | undefined => {
  return managedProductRelatedParties?.find(relatedParty => relatedParty?.isInitialParty)?.partyId ?? undefined;
};

export const getNextPendingSignee = (signees: LegalDocumentSignee[]): LegalDocumentSignee | undefined => {
  // if recipientIds are not provided, we cannot do this calculation
  return signees.some(signee => signee.recipientId === null)
    ? undefined
    : signees
        .sort((a, b) => (a.recipientId ?? 99) - (b.recipientId ?? 99))
        .find(signee => signee.status === LegalDocumentStatus.PENDING) ?? undefined;
};

export const isPartyASignee = (signees: LegalDocumentSignee[], partyId: string): boolean => {
  return !!signees.find(signee => signee.partyId === partyId);
};

export const hasPartyFinishedSigning = (signees: LegalDocumentSignee[], partyId: string): boolean => {
  return signees
    .filter(signee => signee.partyId === partyId)
    .every(signee => signee.status === LegalDocumentStatus.SUCCEEDED);
};

export const accountTypeHasSecondaryAccounts = (accountType?: FinancialAccountType | null): boolean =>
  isJointAccountType(accountType) ||
  isTrustAccountType(accountType) ||
  isCustodialAccountType(accountType) ||
  isCorporateAccountType(accountType);

export const isTrustAccountType = (accountType?: FinancialAccountType | null): boolean =>
  !!accountType &&
  [
    FinancialAccountType.BENEFICIARY_ROTH_IRA_TRUST,
    FinancialAccountType.BENEFICIARY_TRADITIONAL_IRA_TRUST,
    FinancialAccountType.TRUST,
    FinancialAccountType.TRUST_UNDER_AGREEMENT,
    FinancialAccountType.TRUST_UNDER_WILL,
  ].includes(accountType);

export const isJointAccountType = (accountType?: FinancialAccountType | null): boolean =>
  !!accountType &&
  [
    FinancialAccountType.JOINT,
    FinancialAccountType.JOINT_TENANTS_BY_ENTIRETY,
    FinancialAccountType.JOINT_TENANTS_IN_COMMON,
  ].includes(accountType);

export const isJTWROSAccountType = (accountType?: FinancialAccountType | null): boolean =>
  accountType === FinancialAccountType.JOINT;

export const isCustodialAccountType = (accountType?: FinancialAccountType | null): boolean =>
  !!accountType &&
  [
    FinancialAccountType.CUSTODIAL,
    FinancialAccountType.UGMA,
    FinancialAccountType.UTMA,
    FinancialAccountType.BENEFICIARY_ROTH_IRA_MINOR_CUSTODIAN,
    FinancialAccountType.BENEFICIARY_TRADITIONAL_IRA_MINOR_CUSTODIAN,
    FinancialAccountType.CUSTODIAL_ROLLOVER_IRA,
    FinancialAccountType.CUSTODIAL_ROTH_IRA,
    FinancialAccountType.CUSTODIAL_TRADITIONAL_IRA,
  ].includes(accountType);

export const isCorporateAccountType = (accountType?: FinancialAccountType | null): boolean =>
  !!accountType &&
  [
    FinancialAccountType.CORPORATION,
    FinancialAccountType.SOLE_PROPRIETORSHIP,
    FinancialAccountType.PARTNERSHIP,
    FinancialAccountType.LLC,
  ].includes(accountType);

export const VISIBLE_FINANCIAL_ACCOUNT_STATUSES = [
  FinancialAccountStatus.ACTIVE,
  FinancialAccountStatus.ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED,
  FinancialAccountStatus.LEGAL_DOCUMENTS_PREPARED,
  FinancialAccountStatus.LEGAL_DOCUMENTS_SIGNATURE_FAILED,
  FinancialAccountStatus.LEGAL_DOCUMENTS_SIGNED,
  FinancialAccountStatus.LEGAL_DOCUMENTS_SUBMITTED,
  FinancialAccountStatus.NEW,
  FinancialAccountStatus.PARTIAL,
  FinancialAccountStatus.PENDING_CLOSED,
];

export const VISIBLE_ONBOARDING_STATES_FOR_PARTIAL_ACCOUNTS = [
  OnboardingStates.GOALS,
  OnboardingStates.MADLIBS,
  OnboardingStates.RTQ,
  OnboardingStates.RTQ_RESULT,
  OnboardingStates.PORTFOLIO_SELECTION,
  OnboardingStates.PLAN,
  OnboardingStates.ASSET_CONSOLIDATION,
  OnboardingStates.FUNDING,
  OnboardingStates.PAPERWORK,
  OnboardingStates.PLAYBACK,
];

export const isAccountVisible = (status: FinancialAccountStatus, onboardingState?: OnboardingStates) => {
  return VISIBLE_FINANCIAL_ACCOUNT_STATUSES.includes(status)
    ? status === FinancialAccountStatus.PARTIAL
      ? VISIBLE_ONBOARDING_STATES_FOR_PARTIAL_ACCOUNTS.includes(onboardingState ?? OnboardingStates.UNKNOWN)
      : true
    : false;
};

export const shouldEnablePendingPUWWarning = (partyIdFA?: string, state?: AccountState) =>
  !!partyIdFA && state === AccountState.PendingModelPortfolioChange;

export const isBankAccountEditable = (account: BankAccount | null | undefined): boolean =>
  !account?.attributes
    ?.filter(isBooleanAttribute)
    ?.find(({ name }) => name === 'IS_SYNCED_FROM_MANAGED_PRODUCT_ASSOCIATION')?.booleanValue &&
  !account?.products
    ?.filter(isManagedProduct)
    .find(
      ({ financialAccountAssociationVerificationStatus }) =>
        financialAccountAssociationVerificationStatus === FinancialAccountAssociationVerificationStatus.ACCEPTED ||
        financialAccountAssociationVerificationStatus === FinancialAccountAssociationVerificationStatus.IN_REVIEW,
    );

export const getAddAccountActions = ({
  isManualLinkageForAccountSupported,
  isPlaidLinkageForAccountSupported,
  manualLinkText,
  plaidLinkText,
}: {
  isManualLinkageForAccountSupported: boolean;
  isPlaidLinkageForAccountSupported: boolean;
  manualLinkText?: string | null;
  plaidLinkText?: string | null;
}): DropdownItem[] => {
  const actions = [];
  if (isManualLinkageForAccountSupported) {
    actions.push({
      value: 'link-account-action',
      label: manualLinkText ?? 'Link Bank Account Manually',
      icon: <AddIcon />,
    });
  }
  if (isPlaidLinkageForAccountSupported) {
    actions.push({
      value: 'link-plaid-account-action',
      label: plaidLinkText ?? 'Link Bank Account via Plaid',
      icon: <LinkIcon />,
    });
  }
  return actions;
};

/**
 * @param {string} accountId - the selected bank account id
 * @param {string} bankAccounts - Array of bankAccounts which were fetched from symphony
 * @param {string} managedProductId - Managed Product ID to search for the managedProduct to check verification status.
 * @returns {string} Returns true if the selected account is 'ACCEPTED', otherwise false.
 */
export const getIsBankAccountAssociationNonVerified = (
  accountId: string,
  bankAccounts: BankAccount[],
  managedProductId: string,
) => {
  const bankAccount = bankAccounts.find(account => account.id === accountId);
  const accountVerificationStatus = bankAccount?.products
    ?.filter(isManagedProduct)
    .find(product => product.id === managedProductId)?.financialAccountAssociationVerificationStatus;
  return accountVerificationStatus !== FinancialAccountAssociationVerificationStatus.ACCEPTED;
};

export const getMaskedAccountNumberWithFinancialInstitutionName = (
  financialInstitution: string | null,
  maskedAccountNumber?: string | null,
) => `${financialInstitution ?? 'Account'} ${maskedAccountNumber}`;

export const getAccountNumberText = (
  shouldMaskAccountNumber: boolean,
  maskedAccountNumber: string,
  accountNumberPrefix: string,
  accountNumberFormat: string,
) => {
  const accountNumberLastFour = shouldMaskAccountNumber ? maskedAccountNumber : maskedAccountNumber.replace('*', '');
  return `${accountNumberPrefix} ${accountNumberFormat.replace('${accountNumber}', accountNumberLastFour).trim()}`;
};

export const isValidBankAccount = (account: Pick<FinancialAccount, 'accountNumber' | 'routingNumber'>) =>
  !!account.accountNumber && !!account.routingNumber;

export const isInternalFundingAccount = (account?: PortfolioAccount): boolean => account?.source === 'PARTNER_INTERNAL';
